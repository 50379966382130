<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRandomUUID } from "../../composables/useRandomUUID";

const randomId: Ref<string> = ref("");
import { twMerge } from "tailwind-merge";

// Generating random ID::
onMounted(() => {
  randomId.value = useRandomUUID();
});

// Variants Types::
type Sizes = "sm" | "base" | "lg";

// Props::
const { readOnly, modelValue, disabled, dataTestid } = defineProps({
  label: { type: String as PropType<string>, default: null },
  size: { type: String as PropType<Sizes>, default: "base" },
  stars: { type: Number as PropType<number>, default: 5 },
  readOnly: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  appendClass: { type: String as PropType<string>, default: "" },
  modelValue: { type: Number as PropType<number>, default: 0 },
  dataTestid: { type: String as PropType<string>, default: "molecule-ratings" },
});

const updateValue = (value: number) => {
  if (readOnly || disabled) {
    return modelValue;
  }
  return value;
};
</script>

<template>
  <div :class="twMerge('inline-flex items-center', appendClass)" :id="randomId">
    <div
      v-for="(i, index) in stars"
      :key="i"
      @click="$emit('update:modelValue', updateValue(i))"
      :class="[disabled || readOnly ? '' : 'cursor-pointer']"
      :data-testid="dataTestid + '-stars'"
    >
      <ClientOnly>
        <Icon
          v-if="index < Math.floor(modelValue)"
          name="ic:round-star"
          class="text-warning-dark"
          :data-testid="dataTestid"
          :class="
            twMerge([
              disabled && '!text-warning',
              size === 'base' && '!size-[22px] sm:size-6',
              size === 'sm' && 'w-5 h-5',
              size === 'lg' && 'w-10 h-10',
            ])
          "
        />
        <Icon
          v-else
          name="ic:round-star"
          class="text-natural-light-grey dark:text-natural-grey"
          :data-testid="dataTestid"
          :class="
            twMerge([
              size === 'base' && '!size-[22px] sm:size-6',
              size === 'sm' && 'w-5 h-5',
              size === 'lg' && 'w-10 h-10',
            ])
          "
        />
      </ClientOnly>
    </div>
    <div
      :data-testid="dataTestid"
      class="text-natural-grey dark:text-natural-light-grey pl-1"
    >
      {{ label }}
    </div>
  </div>
</template>
